/**
 * Attaches colorbox for the playback of Mantle videos.
 *
 * Templates loading this library need the following:
 *     Data attribute 'play-inline' that corresponds to a checkbox value.
 *     .js-video-block as a class on the template wrapper
 *     See example in: editorial/video_block_v1
 */
var MantleBehavior = MantleBehavior || {};
var MantleVideo = MantleVideo || {};

(function ($, Drupal, generic) {
  var iPhone = /iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  var iPad = /Pad/.test(navigator.userAgent) && !window.MSStream;

  $(document).on('videoBlock.reAttach', function (event, context) {
    Drupal.behaviors.videoBlock.attach(context);
  });

  /**
   * video-ended-final runs after certain async commands that run at
   * the end of video playback.
   */
  $(document).on('video-ended-final', '#foreground-node.colorbox--video', function () {
    generic.overlay.hide();
  });

  Drupal.behaviors.videoBlock = {
    observer: null,
    setupObserver: function () {
      /**
       * IntersectionObserver used to detect video blocks that need to be
       * initialzed before they are clicked.
       */
      var self = this;

      if (self.observer) {
        return;
      }

      var options = {
        root: null,
        rootMargin: '0px',
        threshold: [0.1],
      };

      var handler = function (entries) {
        _.each(entries, function (entry) {
          if (!entry.isIntersecting) {
            return;
          }
          var elem = entry.target;

          self.initializeVideo(elem);
        });
      };

      self.observer = new IntersectionObserver(handler, options);
    },
    /**
     * We want the youtube iframe initialized so we can click to play. But only
     * when the video comes into view.
     *
     * This is for iOS which has strict rules about the timing between a click
     * and when a video plays.
     */
    initializeVideo: function (videoBlock) {
      var $videoBlock = $(videoBlock);
      this.observer.unobserve(videoBlock);
      var elcVideo = $videoBlock.data('video-manager');

      elcVideo.init();
    },

    attach: function (context) {
      var self = this;
      this.setupObserver();

      var $elements = $('.js-video-block', context);

      /* eslint-disable complexity */
      $elements.each(function () {
        var $module = $(this);
        var elcVideo;

        self.getDom($module);

        // Needed for accesibility.
        $module.nodes.$playButton.attr('tabindex', '0');
        $module.nodes.$playButton.on('keyup', function (e) {
          if (e.key === 'Enter') {
            $(this).trigger('click');
          }
        });

        // Need an actual video
        if ($module.nodes.$videoBlock.length === 0) {
          return;
        }

        $module.nodes.$videoBlock.on('video-started', function () {
          $module.nodes.$videoBlock.closest('.js-video-block').addClass('playing-video');
          $module.nodes.$videoBlock.closest('.js-video-block').closest('.js-hero-block-media-wrapper').addClass('playing-video');
        });

        $module.nodes.$videoBlock.on('video-ended, video-paused', function () {
          $(this).closest('.js-video-block').removeClass('playing-video');
          $(this).closest('.js-video-block').closest('.js-hero-block-media-wrapper').removeClass('playing-video');
        });

        // We preload for iOS when the first click needs to trigger play.
        if (iPhone || ($module.settings.playInline && iPad)) {
          self.observer.observe($module.nodes.$videoBlock.get(0));
        }

        if ($module.settings.mute && $module.settings.playInline) {
          $module.nodes.$videoJS.attr('muted', true);
        }

        if ($module.settings.autoplay && $module.settings.playInline) {
          new MantleVideo.MantleVideoPlayer($module.nodes.$videoPlayer);
          $module.nodes.$videoJS.attr('autoplay', 1);
          elcVideo = $module.nodes.$videoBlock.data('video-manager');
          elcVideo.init();
        }

        /**
         * Video playback in overlay is the default.
         * See if we need to take over the start-video event to handle overlay.
         */
        if ($module.settings.playInline) {
          return;
        }

        if (iPhone) {
          // on IOS default to playing in full screen
          $module.nodes.$videoJS.removeAttr('playsinline');
        }

        $module.off('start-video.videoBlock').on('start-video.videoBlock', '.js-videojs-video', function (e) {
          var $videoBlock = $(this);
          var elcVideo = $videoBlock.data('video-manager');

          // Don't let the top level inline stuff handle video blocks.
          e.stopPropagation();

          if (iPhone) {
            /**
             * At this point. We've setup video to not play inline and
             * preloaded the iframe via intersection observer. This should
             * pop the fullscreen view.
             */
            elcVideo.startVideo();

            return;
          }

          var $videoClone = elcVideo.clone();
          var options = {
            content: $videoClone,
            cssClass: 'colorbox--video ' + $module.attr('class'),
            hideOnClickOutside: true,
            includeBackground: true,
            backgroundNodeClickHide: true,
            cssStyle: {
              width: '100%',
              maxWidth: 1024,
              maxHeight: 768
            },
            onShow: function () {
              var cloneController = $videoClone.data('video-manager');
              /**
               * So it's "possible" for ipad to play on overlay click. Same
               * with iphone. But it comes down to a speed issue. Also, videojs
               * currently doesn't handle getting the extra -1 youtube state
               * event well.
               *
               * NOTE: once mantle_video is in colorbox. it won't be caught by
               * start-video.videoBlock. Which is what we want.
               */
              cloneController.init();

              if (!iPad) {
                cloneController.startVideo();
              }
            }
          };
          generic.overlay.launch(options);
        });
      });
    },
    getDom: function ($module) {
      $module.nodes = {};
      $module.settings = {};
      $module.nodes.$videoBlock = $('.js-videojs-video', $module);
      $module.nodes.$videoJS = $('elc-video-js, .video-js', $module.nodes.$videoBlock);
      $module.nodes.$videoPlayer = MantleVideo.MantleVideoPlayer.getVideoPlayerDom($module);
      $module.nodes.$posterImages = $('.js-videojs-landing__image img', $module);
      $module.nodes.$playButton = $('.js-videojs-landing__play', $module.nodes.$videoBlock);
      $module.settings.playInline = $module.data('play-inline');
      $module.settings.autoplay = $module.data('autoplay-video');
      $module.settings.mute = $module.data('mute-video-on-loading');
    }
  };
})(jQuery, Drupal, window.generic || {});
